function createScript(src, async) {
  const element = document.createElement("script");
  element.type = "text/javascript";
  element.classList.add(async ? "js-script-tms-async" : "js-script-tms");
  element.async = async;
  element.dataset.src = src;
  document.head.appendChild(element);
  return element;
}
function createContainers(containers = []) {
  var _a;
  const hostName = ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname) || "";
  let hostSite = getSiteNameByHost(hostName);
  containers.forEach(({
    path,
    type,
    args,
    async,
    sitesAuthorized
  }) => {
    if (!sitesAuthorized || sitesAuthorized.length === 0 || sitesAuthorized.includes(hostSite)) {
      path = path.replace("(", "").replace(")", "");
      switch (type) {
        case "gtm":
          (function (w, d, s, l) {
            w[l] = w[l] || [];
            w[l].push({
              "gtm.start": new Date().getTime(),
              event: "gtm.js"
            });
            const f = d.getElementsByTagName(s)[0],
              j = d.createElement(s);
            j.async = true;
            j.src = path;
            (f.parentNode || document).insertBefore(j, f);
          })(window, document, "script", args[0] || "dataLayer");
          break;
        case "tealium":
          if (!window.utag_data) {
            window.utag_data = {
              page_load: 1
            };
          }
          createScript(path, async);
          break;
        default:
          createScript(path, async);
          break;
      }
    }
  });
}
function getSiteNameByHost(host) {
  if (host != null) {
    if (host.includes("communaute") || host.includes("community") || host.includes("comunidad")) {
      return "community";
    }
    if (host.includes("monsimulateurcouleur") || host.includes("simuladordepintura") || host.includes("simuladordecores") || host.includes("simulatore-colore")) {
      return "colorSimulator";
    }
    return "main";
  }
  return "main";
}
export default createContainers;