import { console } from "./includes/utilities";
function assignByKeys(targetArray, sourceArray, keys) {
  sourceArray.forEach(source => {
    const target = targetArray.find(item => keys.every(key => item[key] === source[key]));
    if (target) {
      Object.assign(target, source);
    } else {
      targetArray.push(source);
    }
  });
}
function processKey(dataLayer, name, value, merge = false) {
  if (!Array.isArray(dataLayer.var_in_conflict)) {
    dataLayer.var_in_conflict = [];
  }
  if (merge) {
    if (dataLayer[name] !== undefined && !Array.isArray(dataLayer[name])) {
      dataLayer.var_in_conflict.push({
        keyName: name,
        keyValue: value,
        keyMerge: merge
      });
      return;
    }
    if (!dataLayer[name]) {
      dataLayer[name] = [];
    }
    if (merge === true) {
      dataLayer[name].push(...value);
      return;
    }
    assignByKeys(dataLayer[name], value, merge);
    return;
  }
  if (dataLayer[name]) {
    dataLayer.var_in_conflict.push({
      keyName: name,
      keyValue: value,
      keyMerge: merge
    });
    return;
  }
  dataLayer[name] = value;
}
function processKeyLegacy(dataLayer, name, value, mergeable) {
  if (!Array.isArray(dataLayer.var_in_conflict)) {
    dataLayer.var_in_conflict = [];
  }
  mergeable = mergeable !== false;
  if (Array.isArray(value)) {
    dataLayer[name] = dataLayer[name] || [];
    switch (name) {
      case "cdl_added_products":
      case "added_products":
        dataLayer[name] = value;
        console.warn('Specific property in data layer fragment must use "merge" attribute', {
          name,
          value
        });
        break;
      case "cdl_products":
      case "product_table":
        if (mergeable) {
          value.forEach((item, index) => {
            dataLayer[name][index] = dataLayer[name][index] || {};
            Object.assign(dataLayer[name][index], item);
          });
        }
        console.warn('Specific property in data layer fragment must use "merge" attribute', {
          name,
          value
        });
        break;
      default:
        if (mergeable || dataLayer[name].length === 0) {
          dataLayer[name].push(...value);
        } else {
          dataLayer[name] = value;
        }
        break;
    }
    return;
  }
  if (dataLayer[name]) {
    dataLayer.var_in_conflict.push({
      keyName: name,
      keyValue: value,
      isMergeable: mergeable
    });
    return;
  }
  dataLayer[name] = value;
}
export default function build(dataLayerFragments) {
  return new Promise(resolve => {
    const dataLayer = {};
    dataLayerFragments.forEach(fragment => {
      fragment.forEach(({
        name,
        value,
        mergeable,
        merge
      }) => {
        if (mergeable) {
          console.warn('Deprecated attribute "mergeable". Use "merge" instead', {
            name,
            value
          });
        }
        if (merge !== undefined) {
          processKey(dataLayer, name, value, merge);
        } else {
          processKeyLegacy(dataLayer, name, value, mergeable);
        }
      });
    });
    return resolve(Object.assign({
      cdl_environment_bot: /bot|crawler|crawling|spider/i.test(window.navigator.userAgent) ? "bot" : "customer"
    }, dataLayer));
  });
}