import createContainers from "./container";
import Tms from "./tms.class";
function main() {
  window.lm.DOMReady(() => {
    const tms = new (Tms(window.lm.Composant))();
    createContainers(tms.target && JSON.parse(tms.target.dataset.containers));
    Object.assign(window.tms, tms.init(window.tms._q));
    delete window.tms._q;
  });
}
if (document.currentScript) {
  main();
}
export default main;