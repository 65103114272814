import { cookie } from "integration-web-core--socle/js/assets/commons/_cookie";
const DEBUG_COOKIE = "tmsDebug";
const DEBUG_LEVEL = {
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  DEBUG: 4
};
const DEFAULT_DEBUG_LEVEL = 1;
const CONSOLE_PARAMS = ["%cTMS", "background-color: cornflowerblue; border-radius: 5px; color: #fff; font-weight: bold; padding: 0px 5px"];
const isDebugLevel = value => {
  const debugCookieValue = Number(cookie.get(DEBUG_COOKIE));
  return (Number.isNaN(debugCookieValue) ? DEFAULT_DEBUG_LEVEL : debugCookieValue) >= value;
};
const clone = object => {
  if (object === null || typeof object !== "object" || object instanceof EventTarget || object instanceof Error) {
    return object;
  }
  if (object instanceof Array) {
    return object.map(clone);
  }
  if (object instanceof Object) {
    return Object.entries(object).reduce((acc, [key, value]) => {
      acc[key] = clone(value);
      return acc;
    }, {});
  }
  return undefined;
};
function displayDebug(obj, functions) {
  if (!isDebugLevel(DEBUG_LEVEL.DEBUG)) return;
  console.debug(...CONSOLE_PARAMS, applyDebug(obj, functions));
}
function applyDebug(obj, functions) {
  Object.entries(functions).forEach(([key, f]) => {
    const buttonName = `__${key}`;
    Object.defineProperty(obj, buttonName, {
      get: function () {
        f.apply(this, arguments);
        delete this[buttonName];
        console.debug(...CONSOLE_PARAMS, applyDebug(obj, functions));
        return true;
      },
      configurable: true
    });
  });
  return obj;
}
const debugConsole = {
  debug(...message) {
    if (isDebugLevel(DEBUG_LEVEL.DEBUG)) {
      console.debug(...CONSOLE_PARAMS, ...message.map(clone));
    }
  },
  trace(...message) {
    if (isDebugLevel(DEBUG_LEVEL.DEBUG)) {
      console.groupCollapsed(...CONSOLE_PARAMS, ...message.splice(0, 1).map(clone));
      console.trace(...message.map(clone));
      console.groupEnd();
    }
  },
  info(...message) {
    if (isDebugLevel(DEBUG_LEVEL.INFO)) {
      console.info(...CONSOLE_PARAMS, ...message.map(clone));
    }
  },
  warn(...message) {
    if (isDebugLevel(DEBUG_LEVEL.WARN)) {
      console.warn(...CONSOLE_PARAMS, ...message.map(clone));
    }
  },
  error(...message) {
    if (isDebugLevel(DEBUG_LEVEL.ERROR)) {
      console.error(...CONSOLE_PARAMS, ...message.map(clone));
    }
    if (message[0] instanceof Error && "DD_RUM" in window && typeof window.DD_RUM === "object" && window.DD_RUM !== null && "onReady" in window.DD_RUM && typeof window.DD_RUM.onReady === "function") {
      window.DD_RUM.onReady(() => {
        if ("DD_RUM" in window && typeof window.DD_RUM === "object" && window.DD_RUM !== null && "addError" in window.DD_RUM && typeof window.DD_RUM.addError === "function") window.DD_RUM.addError(message[0], {
          project_trangram: "KOBI MODULES - TMS"
        });
      });
    }
  }
};
function testFragment(fragment) {
  if (!Array.isArray(fragment)) {
    throw new TypeError(`Fragment should be an Array`);
  }
  fragment.forEach(testFragmentItem);
  return true;
}
function testFragmentItem(item) {
  if (item === null || typeof item !== "object") throw new TypeError(`Fragment item should be an object`);
  if (!("name" in item) || typeof item.name !== "string") throw new TypeError(`Fragment item "name" attribute should be a string`);
  if ("merge" in item && item.merge) {
    let validMerge = typeof item.merge === "boolean" || Array.isArray(item.merge);
    if (validMerge && Array.isArray(item.merge)) {
      validMerge = item.merge.every(mergeItem => typeof mergeItem === "string");
    }
    if (!validMerge) throw new TypeError(`Fragment item "merge" attribute must be a Boolean or an Array of String`);
    if (!("value" in item) || !Array.isArray(item.value)) {
      throw new TypeError(`Fragment item with a truthy "merge" attribute must have a "value" as an Array`);
    }
  }
  return true;
}
const isElementVisible = (element, threshold) => {
  const {
    height,
    width,
    left,
    right,
    top,
    bottom
  } = element.getBoundingClientRect();
  const visibleX = Math.min(right / width, 1) * Math.min((window.innerWidth - left) / width, 1);
  const visibleY = Math.min(bottom / height, 1) * Math.min((window.innerHeight - top) / height, 1);
  return visibleX * visibleY >= threshold;
};
const jsonStringToObj = jsonString => {
  const specialChar = ["á", "à", "â", "ä", "ã", "å", "ç", "é", "è", "ê", "ë", "í", "ì", "î", "ï", "ñ", "ó", "ò", "ô", "ö", "õ", "ú", "ù", "û", "ü", "ý", "ÿ", "æ", "œ", "Á", "À", "Â", "Ä", "Ã", "Å", "Ç", "É", "È", "Ê", "Ë", "Í", "Ì", "Î", "Ï", "Ñ", "Ó", "Ò", "Ô", "Ö", "Õ", "Ú", "Ù", "Û", "Ü", "Ý", "Ÿ", "Æ", "Œ"];
  const isAlphaCharacter = char => char >= "a" && char <= "z" || char >= "A" && char <= "Z" || char >= "0" && char <= "9" || specialChar.indexOf(char) > -1;
  const unescapedJsonString = jsonString.replace("\\'", "'");
  const stringInArray = unescapedJsonString.split("");
  const convertedArray = stringInArray.map((letter, index, array) => {
    if (letter === "'") {
      if (isAlphaCharacter(array[index - 1]) && isAlphaCharacter(array[index + 1])) {
        return "'";
      } else return '"';
    } else if (letter === '"') {
      if (isAlphaCharacter(array[index - 1]) && isAlphaCharacter(array[index + 1])) return '\\"';else return '"';
    } else return letter;
  });
  const convertedString = convertedArray.join("");
  return JSON.parse(convertedString);
};
function getParents(element) {
  const parents = [];
  while (element) {
    if (!(element instanceof Element)) break;
    parents.unshift(element);
    element = element.parentElement;
  }
  return parents;
}
export { debugConsole as console, displayDebug, jsonStringToObj, testFragment, isElementVisible, getParents };